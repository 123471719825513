@import url('../../colors.css');

/* ensure equal-height columns */
.review-widget .border-helper,
.review-widget-inner {
  height: 100%;
}

.review-widget-inner {
  background: #FFFFFF;
}

.review-widget .border-helper {
  --clipAmount: 25px;
  background: var(--borderColor);
  clip-path: polygon(0% var(--clipAmount), var(--clipAmount) 0%, 100% 0%, 100% calc(100% - var(--clipAmount)), calc(100% - var(--clipAmount)) 100%, 0% 100%);
  padding: 1px;
}

.review-widget .container {
  padding: 1rem;
}

.review-widget h2 {
  margin-top: 0;
}
