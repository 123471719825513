@import url('../../colors.css');

.review {
  border-left: 5px solid var(--borderColor);
  padding-left: 15px;
}

.review dd {
  margin: 0;
}

.review footer {
  font-style: italic;
}

.review dt,
.review .plain-text {
  display: none;
}

.review svg {
  height: 7.5vh;
  width: auto;
}

.review svg [class^=vogel] {
  fill: var(--disabledColor);
}

.review .rating-1 svg .vogel-1,
.review .rating-2 svg .vogel-1,
.review .rating-2 svg .vogel-2,
.review .rating-3 svg .vogel-1,
.review .rating-3 svg .vogel-2,
.review .rating-3 svg .vogel-3,
.review .rating-4 svg .vogel-1,
.review .rating-4 svg .vogel-2,
.review .rating-4 svg .vogel-3,
.review .rating-4 svg .vogel-4,
.review .rating-5 svg [class^=vogel] {
  fill: var(--accentColor);
}