.about .bio {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, auto));
}

.about .bio .bio-image {
  text-align: center;
}

.about .bio .bio-image svg {
  height: auto;
  width: 250px;
}