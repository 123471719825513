:root {
  --accentColor: #c34b21;
  --activeColor: rgb(135, 39, 41);
  --bgColor: rgb(248, 243, 223);
  --borderColor: rgba(81, 42, 43, 0.25);
  --contrastBgColor: rgb(81, 42, 43);
  --disabledColor: rgb(187, 176, 176);
  --inactiveColor: #412222;
  --shadowColor: rgba(0, 0, 0, 0.4);
}
