@import url(../../colors.css);
@import url(../../spacing.css);

.eatery {
  --min-col-width: 45%;
}

@media (max-width: 999px) {
  .eatery {
    --min-col-width: 100%;
  }
}

.eatery-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-col-width), 1fr));
  gap: 0 5%;
}

.eatery-picture {
  grid-row: span 2;
}

.eatery-picture .border-helper,
.eatery-picture img {
  clip-path: polygon(0% 8%, 8% 0%, 100% 0%, 100% 92%, 92% 100%, 0% 100%);
}

.eatery-picture .border-helper {
  background: var(--borderColor);
  padding: 1px;
}

.eatery-picture img {
  /** addresses phantom spacing after image: https://stackoverflow.com/a/5804278 */
  display: block;
}

.eatery-wrapper address {
  align-self: end;
  padding-top: var(--gutterHeight);
}