@import url(../../spacing.css);

.menu {
  background: white;
  border: 1px solid black;
  margin-top: var(--gutterHeight);
  padding: calc(var(--gutterHeight)/2) calc(var(--gutterWidth)/2);
  text-align: center;
}

.menu hgroup p {
  font-style: italic;
}