@import url(../../colors.css);
@import url(../../spacing.css);

/**
 * The header has basically three states:
 * - user has a wide screen (this is the default style, i.e., not wrapped by media query)
 *     - the logo displays
 *     - the other menu items display
 *     - the hamburger is hidden
 * - user has a narrow screen (as defined by the media query) and has not clicked the hamburger
 *     - the logo displays
 *     - the other menu items are hidden
 *     - the hamburger is displayed
 * - user has a narrow screen (as defined by the media query) and hamburger is clicked (i.e., header
 *   has fullscreen class)
 *     - the header takes over the full screen
 *     - the logo displays
 *     - the other menu items are displayed
 *     - hamburger is displayed upside down
 */

#site-header {
  background-color: var(--contrastBgColor);
  padding: 2rem var(--gutterWidth) 0;
  text-align: left;
}

#site-header nav {
  align-items: flex-end;
  display: flex;
}

#site-header nav .site-menu {
  align-items: baseline;
  display: flex;
  flex-grow: 5;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#site-header nav .site-menu .home a,
#site-header nav .site-menu .home a:hover,
#site-header nav .site-menu .home a.active {
  background: transparent;
  padding-left: 0;
}

#site-header nav .site-menu a {
  background: var(--inactiveColor);
  clip-path: polygon(0% 15px, 15px 0%, 100% 0%, 100% 100%, 0% 100%);
  color: white;
  display: inline-block;
  padding: 1rem 2rem;
  text-decoration: none;
}

#site-header nav .site-menu a:hover {
  background-color: var(--activeColor);
}

#site-header nav .site-menu a.active {
  background-color: var(--accentColor);
}

#site-header nav .site-menu .home a {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

#site-header .logo svg {
  height: 50px;
  width: auto;
}

#site-header .logo svg path {
  /* important overrides the SVG's inline style */
  fill: white !important;
}

#site-header .home .title {
  font-weight: 800;
  padding-bottom: 5px;
}

#site-header .home .subtitle {
  font-size: 80%;
}

#site-menu-toggle {
  cursor: pointer;
  display: none;
  padding: 1rem 0;
  transition: all 2s ease;
}

#hamburger svg {
  height: 50px;
  transition: all 2s ease;
  width: auto;
}

@media (max-width: 999px) {
  #site-menu-toggle {
    display: inline-block;
  }

  #site-header nav .site-menu li:not(.home) {
    display: none;
  }

  #site-header.fullscreen {
    background: var(--contrastBgColor);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }

  #site-header.fullscreen nav .site-menu {
    align-items: stretch;
    flex-flow: column wrap;
  }

  #site-header.fullscreen nav .site-menu .home {
    margin-right: 55px;
  }

  #site-header.fullscreen nav .site-menu .home a {
    min-width: calc(100% - 2rem);
    padding-right: 2rem;
  }


  #site-header.fullscreen nav .site-menu li {
    display: list-item;
  }

  #site-header.fullscreen nav .site-menu li a {
    background: none;
    clip-path: none;
    min-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  #site-header.fullscreen #site-menu-toggle {
    position: absolute;
    top: 2rem;
    right: var(--gutterWidth);
    transform: rotate(1260deg);
  }

  #site-header.fullscreen #hamburger svg {
    filter: contrast(50%);
  }
}
