@import url(../../colors.css);

[class^=edible] {
  cursor: pointer;
  padding: 15px 0;
}

.edible-closed:hover {
  background: radial-gradient(closest-side, var(--activeColor) -500%, transparent 80%);
}

[class^=edible] h3 {
  color: var(--accentColor);
  filter: brightness(100%);
}

[class^=edible]:hover h3 {
  color: var(--activeColor);
  text-decoration: underline;
}

:not(:last-child)[class^=edible]::after {
  content: " ";
  background-image: url("/public/img/theme/dots.svg");
  background-size: cover;
  display: inline-block;
  position: relative;
  height: 10px;
  width: 37px;
}

[class^=edible] img {
  border: 1px solid var(--borderColor);
}

[class^=edible] .reviews {
  text-align: left;
}
